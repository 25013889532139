import React from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import Menu from './components/Menu';
import Education from './pages/Education';
import Experience from './pages/Experience';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Skills from './pages/Skills';

import { ROUTES } from './constants';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import WordleHelper from './pages/WordleHelper';

const App: React.FC = () => {
	return (
		<IonApp>
			<IonReactRouter>
				<IonSplitPane contentId="main">
					<Menu />
					<IonRouterOutlet id="main">
						<Route path="/" exact={true}>
							<Redirect to={ROUTES.HOME} />
						</Route>
						<Route path={ROUTES.HOME} exact={true}>
							<Home />
						</Route>
						{/*
						<Route path={ROUTES.ABOUT} exact={true}>
							<About />
						</Route>
						*/}
						<Route path={ROUTES.EDUCATION} exact={true}>
							<Education />
						</Route>
						<Route path={ROUTES.EXPERIENCE} exact={true}>
							<Experience />
						</Route>
						<Route path={ROUTES.SKILLS} exact={true}>
							<Skills />
						</Route>
						<Route path={ROUTES.WORDLE_HELPER} exact={true}>
							<WordleHelper />
						</Route>
						<Route>
							<NotFound />
						</Route>
					</IonRouterOutlet>
				</IonSplitPane>
			</IonReactRouter>
		</IonApp>
	);
};

export default App;
